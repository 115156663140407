<template>
  <div class="heart">
    <span v-if="count" class="heart__number">{{ count }}</span>
    <span
      class="heart__image"
      :class="{
        'heart__image--empty': !count,
        'heart__image--filled': value,
        'heart__image--disabled': disabled
      }"
      @click="onClickDebounced"
    />
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
  name: 'Heart',
  props: {
    count: {
      type: Number,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ onClickDebounced: null }),
  created() {
    this.onClickDebounced = debounce(this.onClick, 500, {
      leading: true,
      trailing: false
    });
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value);
    }
  }
};
</script>

<style lang="scss" scoped src="./heart.scss"></style>
