export const authMixin = {
  methods: {
    openAuthIfNeeded() {
      const loggedIn = this.$auth.loggedIn;

      if (!loggedIn) {
        this.$bus.$emit('auth:login:show');
      }

      return loggedIn;
    }
  }
};
