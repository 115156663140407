<template>
  <div ref="productThumb" :class="{ product: true, 'product--sold': isSold }">
    <div class="product__author">
      <NuxtLink
        :to="localePath({ name: 'Member', params: { slug: item.seller.slug } })"
      >
        <img
          v-if="sellerMediaSrc"
          class="product__author__picture lazy-img-fadein"
          :src="sellerMediaSrc"
          :srcset="sellerMediaSrcset"
          loading="lazy"
          alt=""
        />
        <img
          v-else
          class="product__author__picture"
          src="~/assets/img/user-xs.png"
          alt=""
        />
      </NuxtLink>
      <NuxtLink
        :to="localePath({ name: 'Member', params: { slug: item.seller.slug } })"
        class="product__author__name"
      >
        {{ item.seller?.username || '' }}
      </NuxtLink>
      <span v-if="isBoosted" class="product__author__boosted">
        {{ $t('product-card.boosted') }}
      </span>
    </div>
    <NuxtLink
      v-if="!noLink"
      :to="localePath({ name: 'ProductCard', params: { slug: item.slug } })"
      class="product__picture-container"
    >
      <!-- <a :href="href" role="link" @click="onNavigate(navigate, href, $event)"> -->
      <img
        v-if="item.mainMedia"
        class="product__picture lazy-img-fadein"
        :src="mainMediaSrc"
        :srcset="mainMediaSrcset"
        loading="lazy"
        :alt="item.title"
        @click="triggerClickEvent"
      />
      <div v-if="isSold" class="product__state-banner">
        <div class="product__state-banner__banner">
          {{ $t('components.product-list.product.sold') }}
        </div>
      </div>
      <div
        v-else-if="isDraft"
        class="product__state-banner product__state-banner--mine-shaft"
      >
        <div class="product__state-banner__banner">
          {{ $t('components.product-list.product.draft') }}
        </div>
      </div>
      <div
        v-else-if="isWithdrawn"
        class="product__state-banner product__state-banner--dove-gray"
      >
        <div class="product__state-banner__banner">
          {{ $t('components.product-list.product.withdrawn') }}
        </div>
      </div>
      <div
        v-else-if="isRejected"
        class="product__state-banner product__state-banner--red"
      >
        <div class="product__state-banner__banner">
          {{ $t('components.product-list.product.rejected') }}
        </div>
      </div>
      <!-- </a> -->
    </NuxtLink>
    <div
      v-else
      class="product__picture-container"
      @click="$emit('click:picture', $event)"
    >
      <img
        class="product__picture"
        :src="item.mainMedia.contentUrl"
        :alt="item.title"
      />
    </div>
    <div class="product__infos">
      <h2 v-line-clamp="1" class="product__infos__name" :title="item.title">
        {{ item.title }}
      </h2>
      <div v-if="item.brand" v-line-clamp="1" class="product__infos__brand">
        {{ item.brand.name }}
      </div>
      <div class="product__infos__size">
        <template v-if="item.size && !item.size.virtual">
          {{ item.size.name }}
        </template>
      </div>
      <div class="product__infos__price">{{ item.price | toCurrency }}</div>
      <Heart
        v-if="!isSelf && !isSold"
        v-model="liked"
        class="product__infos__like"
        :count="likeCount"
        :disabled="!$auth.user"
      />
    </div>
    <div class="product__bottom">
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>
import Heart from '@/components/Heart/Heart';
import { authMixin } from '@/mixins/auth';
import { captureErrorMixin } from '@/mixins/captureError';
import mediaObject from '@/mixins/mediaObject';
import { FORMATS } from '@/misc/media-object';
import isNil from 'lodash.isnil';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { eventViewItem, eventClickItem } from '@/api/item';

export default {
  name: 'Product',
  components: { Heart },
  mixins: [authMixin, captureErrorMixin, mediaObject],
  props: {
    item: {
      type: Object,
      default: null
    },
    noLink: {
      type: Boolean,
      default: false
    },
    queryID: {
      type: String,
      default: null
    },
    position: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      seller: null,
      mainPicture: null,
      brand: null,
      size: null,
      likeCount: this.item.likeCount,
      observer: null,
      visibilityTimeout: null
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFavoriteItem']),
    liked: {
      get() {
        return this.hasFavoriteItem(this.item.id);
      },
      set(liked) {
        if (!this.openAuthIfNeeded()) return;
        if (liked) {
          this.likeItem(this.item['@id']).then(
            (res) => res && this.likeCount++
          );
        } else {
          this.unlikeItem(this.item['@id']).then(
            (res) => res && this.likeCount--
          );
        }
      }
    },
    isSelf() {
      if (!this.$auth.loggedIn) return false;
      return this.$auth.user.id === this.item.seller.id;
    },
    isBoosted() {
      return !isNil(this.item?.boostedUntil);
    },
    isSold() {
      return this.item?.publicationState === 'sold';
    },
    isDraft() {
      return this.item?.publicationState === 'draft';
    },
    isRejected() {
      return this.item?.publicationState === 'rejected';
    },
    isWithdrawn() {
      return this.item?.publicationState === 'withdrawn';
    },
    mainMediaSrc() {
      return this.mediaSrc(this.item.mainMedia, FORMATS.item.productList);
    },
    mainMediaSrcset() {
      return this.mediaSrcset(this.item.mainMedia, FORMATS.item.productList);
    },
    sellerMediaSrc() {
      if (isNil(this.item.seller?.avatar)) return null;
      return this.mediaSrc(this.item.seller.avatar, FORMATS.user.header);
    },
    sellerMediaSrcset() {
      if (isNil(this.item.seller?.avatar)) return '';
      return this.mediaSrcset(this.item.seller.avatar, FORMATS.user.header);
    }
  },
  mounted() {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8
    };
    this.observer = new IntersectionObserver(this.intersectionHandler, options);
    this.queryID && this.observer.observe(this.$refs.productThumb);
  },
  beforeDestroy() {
    this.unobserve();
  },
  methods: {
    ...mapActions(useUserStore, ['likeItem', 'unlikeItem']),
    async intersectionHandler(entries) {
      if (!this.$auth.loggedIn) return;
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          this.visibilityTimeout = setTimeout(this.triggerItemView, 2000);
        } else {
          if (this.visibilityTimeout) {
            clearTimeout(this.visibilityTimeout);
            this.visibilityTimeout = null;
          }
        }
      });
    },
    async triggerItemView() {
      await this.try(
        async () => {
          await this.$api.doRequest(eventViewItem, this.item.id, this.queryID);
        },
        null,
        null,
        { sentryCapture: false }
      );
      this.unobserve();
    },
    unobserve() {
      this.observer &&
        this.$refs.productThumb &&
        this.observer.unobserve(this.$refs.productThumb);
    },
    async triggerClickEvent() {
      if (this.$auth.loggedIn) {
        await this.try(
          async () => {
            await this.$api.doRequest(
              eventClickItem,
              this.item.id,
              this.queryID,
              this.position + 1
            );
          },
          null,
          null,
          { sentryCapture: false }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped src="./product.scss"></style>
